import { appConfig } from "~/utils/config";
import logoUrl from "-!file-loader?esModule=false!~/assets/img/adidas-logo.svg?raw";
export default {
    data() {
        return {
            logoStructuredData: {
                type: "application/ld+json",
                json: {
                    "@context": "https://schema.org/",
                    "@type": "Organization",
                    "url": `${ appConfig?.mainInfo?.globalInfo?.url }`,
                    "legalName": `${ appConfig?.mainInfo?.globalInfo?.name }`,
                    "address": {
                        "@type": "PostalAddress",
                        "addressLocality": `${ appConfig?.mainInfo?.globalInfo?.addressLocality }`,
                    },
                    "contactPoint": [
                        {
                            "@type": "ContactPoint",
                            "telephone": `${ appConfig?.mainInfo?.contacts?.globalPhone }`,
                            "contactType": "customer service"
                        }
                    ],
                    "logo": `${ this.$config?.baseURL }${ logoUrl }`,
                    "sameAs": [
                        `${ appConfig?.mainInfo?.socialBlock?.instagram?.link }`,
                        `${ appConfig?.mainInfo?.socialBlock?.facebook?.link }`,
                    ]
                }
            }
        };
    }
};
